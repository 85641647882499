import React, {  useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import {Column} from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';

const ContactTable = ({ contactData }) => {

  const tableRef = useRef(null);

  const contactColumns = [
      { field: 'anPosition', header: 'Position' },
      { field: 'proteinUid', header: 'Uniprot Accession' },
      { field: 'pdbId', header: 'PDB ID' },
      { field: 'contactPosition', header: 'Contact position' }
    ];

  const renderContactColumns = contactColumns.map(col => {
      return <Column key={col.field} field={col.field} header={col.header} sortable />;
    });

const exportCSV = (tableRef, selectionOnly) => {
        tableRef.current.exportCSV({ selectionOnly });
  };

  const exportExcel = async (dataToExport) => {
    const xlsx = await import('xlsx');
    const worksheet = xlsx.utils.json_to_sheet(dataToExport);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcelFile(excelBuffer, 'data');
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

  const exportPdf = (dataToExport, columns) => {
    import('jspdf').then((jsPDF) => {
        import('jspdf-autotable').then(() => {
            const doc = new jsPDF.default('landscape');
            // Ensure columns are formatted as needed for jsPDF autoTable
            const formattedColumns = columns.map(col => ({title: col.header, dataKey: col.field}));
            doc.autoTable(formattedColumns, dataToExport);
            doc.save('data.pdf');
        });
    });
    };

     const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
    });

   const value = filters['global'] ? filters['global'].value : '';

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };


 const headerContactInter = (
  <div className="row align-items-center">
      <div className="col mb-2">
          <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)}
              placeholder="Global Search" />
          </span>
      </div>
      <div className="col" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Button type="button" icon="pi pi-file" className="p-button-rounded p-mr-2" onClick={() => exportCSV(tableRef, false)} data-pr-tooltip="CSV" />
          <Button type="button" icon="pi pi-file-excel" className="p-button-success p-button-rounded p-mr-2" onClick={() => exportExcel(contactData)} data-pr-tooltip="XLS" />
          <Button type="button" icon="pi pi-file-pdf" className="p-button-warning p-button-rounded" onClick={() => exportPdf(contactData, contactColumns)} data-pr-tooltip="PDF" />
      </div>
  </div>
);

  return (
<>
 <DataTable ref={tableRef} value={contactData} paginator rows={5}
 removableSort header={headerContactInter} filters={filters}
 onFilter={(e) => setFilters(e.filters)} tableStyle={{ minWidth: '50rem' }}>
          {renderContactColumns}
  </DataTable>
</>
  )
}

export default ContactTable