import React, { useEffect, useRef, useMemo } from 'react';
import { createPluginUI } from 'molstar/lib/mol-plugin-ui';
import { ColorNames } from 'molstar/lib/mol-util/color/names';
import 'molstar/lib/mol-plugin-ui/skin/light.scss';
import { renderReact18 } from 'molstar/lib/mol-plugin-ui/react18';
import { PluginCommands } from 'molstar/lib/mol-plugin/commands';
import { Script } from 'molstar/lib/mol-script/script';
import { StructureSelection } from 'molstar/lib/mol-model/structure/query';
import { MolScriptBuilder as MS, MolScriptBuilder } from 'molstar/lib/mol-script/language/builder';
import { StateTransforms } from 'molstar/lib/mol-plugin-state/transforms';
import { createStructureRepresentationParams } from 'molstar/lib/mol-plugin-state/helpers/structure-representation-params';
import { Color } from "molstar/lib/mol-util/color";
import { setStructureOverpaint } from 'molstar/lib/mol-plugin-state/helpers/structure-overpaint'
import { Structure, StructureElement } from 'molstar/lib/mol-model/structure';
import { getSeqFeatures} from '../../ApiFunctions.js';

function setSequenceEntity (entity){

        const selectElement = document.querySelector('select.msp-form-control:nth-child(5)');

        if (selectElement) {

          const initialValue = "0|" + entity;
          selectElement.value = initialValue;

          const event = new Event('change', { bubbles: true });

          selectElement.dispatchEvent(event);

          selectElement.disabled = true;
        }
}

const MolstarViewer = ({ pdbId, tab, positions, chain, database, uniAcc }) => {
  const viewerRef = useRef(null);
  const pluginInstanceRef = useRef(null);

  const initMolstar = useMemo(() => async () => {
    if (viewerRef.current && !pluginInstanceRef.current) {
      const plugin = await createPluginUI({
        target: viewerRef.current,
        layoutIsExpanded: false,
        layoutShowControls: false,
        layoutShowRemoteState: false,
        layoutShowSequence: true,
        layoutShowLog: false,
        layoutShowLeftPanel: false,
        viewportShowExpand: false,
        viewportShowSelectionMode: false,
        viewportShowAnimation: false,
        backgroundColor: 'white',
        render: renderReact18,
      });
      pluginInstanceRef.current = plugin;

      try {

       // Set structure database
       const urlStructure = database === 'AF'
          ? `https://alphafold.ebi.ac.uk/files/${pdbId}-F1-model_v4.pdb`
          : `https://files.rcsb.org/download/${pdbId}.pdb`;

      const data = await pluginInstanceRef.current.builders.data.download(
          { url: urlStructure },
          { state: { isGhost: true } }
        );

        const trajectory = await pluginInstanceRef.current.builders.structure.parseTrajectory(data, 'pdb');
        const structure = await pluginInstanceRef.current.builders.structure.hierarchy.applyPreset(
          trajectory,
          'default'
          , {
            showUnitcell: false,
            representationPreset: 'auto',
        }
        );

        // Change background color
        const renderer = plugin.canvas3d.props.renderer;
        PluginCommands.Canvas3D.SetSettings(plugin, { settings: { renderer: { ...renderer, backgroundColor: ColorNames.white } } });

        const dta = plugin.managers.structure.hierarchy.current.structures[0]?.cell.obj?.data;
        if (!dta) return;


        // Show variants as spheres
        if (tab === 'variants'){

            // Select multiples residues in the sequence
            const selection = Script.getStructureSelection(Q => Q.struct.generator.atomGroups({
            "chain-test": Q.core.rel.eq([MolScriptBuilder.struct.atomProperty.macromolecular.auth_asym_id(), chain]),
            'residue-test': Q.core.set.has([Q.set(...positions), Q.ammp('auth_seq_id')]),
            'atom-test': MS.core.set.has([MS.set('CA'), MS.ammp('label_atom_id')]), // Get only carbon alpha
            'group-by': Q.struct.atomProperty.macromolecular.residueKey(),}), dta);

            // Get polymer representation
            const cartoon = structure.representation.representations.polymer;

            // Create and apply custom representation
            const reprParamsStructureResetColor = createStructureRepresentationParams(plugin, undefined, {
              type: 'cartoon',
              color: 'uniform',
              colorParams: { value: Color("0XFAFAFA") }
            });

            // Create and apply custom representation - Show residue as spacefill (Displays atomic/coarse elements as spheres)
            const reprParams = createStructureRepresentationParams(plugin, undefined, {
              type: 'spacefill',
              color: 'uniform',
              colorParams: { value: Color(0xc93c20) },
              size: 'physical',
              sizeParams: { scale: 1 }
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureResetColor);

            const update2 = plugin
            .build()
            .to(cartoon)
             .apply(StateTransforms.Model.StructureSelectionFromExpression, { label: 'Surroundings', expression: selection })
             .apply(StateTransforms.Representation.StructureRepresentation3D, reprParams);

            await update.commit();
            await update2.commit();

         // Show contact as yellow cartoon
        }else if (tab === 'contacts'){

            // Get polymer representation
            const cartoon = structure.representation.representations.polymer;

             // Create and apply custom representation
            const reprParamsStructureResetColor = createStructureRepresentationParams(plugin, undefined, {
              type: 'cartoon',
              color: 'uniform',
              colorParams: { value: Color("0XFAFAFA") }
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureResetColor);

            const i = await getSeqFeatures(pdbId, uniAcc);

            //setSequenceEntity(i);
//
//             const sequenceDta = dta.model.sequence.sequences;
//
//             const result = sequenceDta.find(item => item.entityId === i);
//
//             const sequence = result ? result.sequence : null;
//
//             const indexMap = sequence ? sequence.indexMap : null

            // Set script language
            const language = 'mol-script';

            // Create params object
            const params = {
                layers: []
            };

            // Add layers to params object
            for (let i = 0; i < positions.length; i++) {
                params.layers.push({
                    script: Script(
                         `(sel.atom.atom-groups
                             :chain-test (= atom.auth_asym_id ${chain} )
                             :residue-test (set.has (set ${positions[i]}) atom.auth_seq_id)
                          )`,
                        language
                    ),
                    color: Color(0xf0e68c),
                    clear: false
                });

//                 if (indexMap) {
//
//                      const newPosition = indexMap.get(parseInt(positions[i]))
//
//                       // Apply contact class to the corresponding span element
//                      const span = document.querySelector(`span[data-seqid="${newPosition}"]`);
//                      if (span) {
//                             span.classList.add('contact');
//                      }
//                 }
            }

            // Apply yellow color
            const update2 = plugin.build();
            update
                  .to(cartoon)
                  .apply(StateTransforms.Representation.OverpaintStructureRepresentation3DFromScript, params);

            await update.commit();
            await update2.commit();

        }else if (tab === 'ligands'){

            // Get polymer representation
            const cartoon = structure.representation.representations.polymer;

             // Create and apply custom representation
            const reprParamsStructureResetColor = createStructureRepresentationParams(plugin, undefined, {
              type: 'cartoon',
              color: 'uniform',
              colorTheme: { name: 'interaction-type', params: { } }
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureResetColor);

            await update.commit();

        }

      } catch (error) {
        console.error('Failed to load the PDB structure:', error);
      }
    }
  }, [pdbId, tab, positions, chain, database, uniAcc]);

  useEffect(() => {
    initMolstar();

    return () => {
      if (pluginInstanceRef.current) {
        pluginInstanceRef.current.dispose();
        pluginInstanceRef.current = null;
      }
    };
  }, [initMolstar]);

  return <div key={pdbId} ref={viewerRef} style={{ width: '100%', height: '100%' }} />;
};

export default MolstarViewer;
