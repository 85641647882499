import React from 'react';

const VariantSelect = ({ variantData , positionSummary,  handleVariantPosition, selectedVariants}) => {

  const uniquePositions = [...new Set(variantData.map(variant => variant.proteinPosition))];

  return (
     <select
            className="form-select mb-3"
            name="structure-inter"
            value={positionSummary}
            onChange={handleVariantPosition}  disabled={selectedVariants.length > 0 } >
              <option value=""></option>
              {uniquePositions.sort((a, b) => a - b).map(position => (
                <option key={position} value={position}>
                  {position}
                </option>
              ))}
    </select>
  );
};

export default VariantSelect;
