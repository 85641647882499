import React from 'react';
import { Chart } from 'primereact/chart';

// Fixed color dictionary
const naturalColorMap = {
  "Missense": "#66D8C9",
  "Deletion": "#00B266",
  "Insertion": "#FF0080"
};

// Get color for labels based on type
const getColor = (label, type) => {
  if (type === 'unAnnNatVariants') {
    return naturalColorMap[label] || '#000000';
  }
  return '#000000';
};

const BarChartFunctional = ({ data, annotation }) => {
  // Filter `data` for residues with specific variants
  const variantData = data.filter(residue =>
    residue.unAnnNatVariants.includes("VAR") || residue.unAnnNatVariants.includes("del") || residue.unAnnNatVariants.includes("I")
  );

  // Count occurrences for Missense (VAR) and Deletion (del)
  const missenseCount = variantData.filter(residue => residue.unAnnNatVariants.includes("VAR")).length;
  const deletionCount = variantData.filter(residue => residue.unAnnNatVariants.includes("del")).length;
  const insertionCount = variantData.filter(residue => residue.unAnnNatVariants.includes("I")).length;

  // Define labels and counts for chart
  const filteredVariant = [
    { label: "Missense", count: missenseCount },
    { label: "Deletion", count: deletionCount },
    { label: "Insertion", count: insertionCount }
  ];

  // Prepare datasets for the chart
  const datasets = filteredVariant.map(variant => ({
    type: 'bar',
    label: variant.label,
    backgroundColor: getColor(variant.label, 'unAnnNatVariants'),
    data: [variant.count]  // Single data point per variant type
  }));

  // Define labels and datasets for the chart
  const stackedData = {
    labels: ["Variants"],
    datasets: datasets,
  };

  // Chart options
  const stackedOptions = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    aspectRatio: 1,
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      legend: {
        labels: {
          color: '#495057',
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: '#495057',
        },
        grid: {
          color: '#ebedef',
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: '#495057',
        },
        grid: {
          color: '#ebedef',
        },
      },
    },
  };

  return (
    <div>
      <div className="card">
        <Chart type="bar" data={stackedData} options={stackedOptions} />
      </div>
    </div>
  );
};

export default BarChartFunctional;
