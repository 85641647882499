import React, { useState, useRef } from 'react';
import { Fieldset } from 'primereact/fieldset';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';

const GraphlqlQuery = ({type, target, tab}) => {

 const [messsage, setMessage] = useState("Click to copy");
 const textareaRef = useRef(null);

 const handleClick = () => {
    setMessage("Copied");

    if (textareaRef.current) {
      const tempTextarea = document.createElement('textarea');
      tempTextarea.value = textareaRef.current.value;
      document.body.appendChild(tempTextarea);
      tempTextarea.select();
      document.execCommand('copy');
      document.body.removeChild(tempTextarea);
    }
  };

  const query =
  type === "gene" && tab === 0
    ? `query getResiduesByGene{
          residuesByGeneSymbol(geneSymbol: "${target.toUpperCase()}", pagination: { offset: 0, limit: 10 }) {
            anPosition #Residue position
            unRefProtRe #Uniprot reference proteome residue
            afRes #AlphaFold residue
            unAnnNatVariants #UniProt annotated natural variants
            unMoleculeProcessing #signal-peptide/transit-peptide/pro-peptide/chain
            unLocation #localization with respect to membranes
            unMotives #UniProt annotated motives
            unDomains #UniProt annotated domains
            pfamDomainPosHmm #PFAM domain and aligned position in the HMM
            unLigandsBindingSite #UniProt annotated ligands (binding site)
            unAnnPropActiveSite #UniProt annotated properties (active site)
            phosPtms #list of annotated Phosphosite PTMs
            unGlycosylations #UniProt annotated glycosylations
            unLipidations #UniProt annotated lipidations
            unModifiedRes #UniProt annotated modified residues
            unDisulfideBongBridges #UniProt annotated disulphide-bond bridges
            unSecondaryStructure #UniProt annotated secondary structure
            afPredictedSecondaryStructure #AlphaFold predicted secondary structure
            afConfidenceValue #AlphaFold confidence value
            afMaxPredictedNumInteractionResidue #AlphaFold maximum predicted number of interacting residues in a single structure (r = 8A)
            afResiduePredictedInteraction #AlphaFold list of residue with a predicted interaction in at least one structure (r = 8A)
            unCrossLinks #UniProt annotated crosslinks
            inBindingRegion #Binding region IntAct
          }
        }`
    : type === "protein" && tab === 0
    ? `query getResiduesByProtein{
          residues(proteinAn: "${target.toUpperCase()}", pagination: { offset: 0, limit: 10}){
            anPosition #Residue position
            unRefProtRe #Uniprot reference proteome residue
            afRes #AlphaFold residue
            unAnnNatVariants #UniProt annotated natural variants
            unMoleculeProcessing #signal-peptide/transit-peptide/pro-peptide/chain
            unLocation #localization with respect to membranes
            unMotives #UniProt annotated motives
            unDomains #UniProt annotated domains
            pfamDomainPosHmm #PFAM domain and aligned position in the HMM
            unLigandsBindingSite #UniProt annotated ligands (binding site)
            unAnnPropActiveSite #UniProt annotated properties (active site)
            phosPtms #list of annotated Phosphosite PTMs
            unGlycosylations #UniProt annotated glycosylations
            unLipidations #UniProt annotated lipidations
            unModifiedRes #UniProt annotated modified residues
            unDisulfideBongBridges #UniProt annotated disulphide-bond bridges
            unSecondaryStructure #UniProt annotated secondary structure
            afPredictedSecondaryStructure #AlphaFold predicted secondary structure
            afConfidenceValue #AlphaFold confidence value
            afMaxPredictedNumInteractionResidue #AlphaFold maximum predicted number of interacting residues in a single structure (r = 8A)
            afResiduePredictedInteraction #AlphaFold list of residue with a predicted interaction in at least one structure (r = 8A)
            unCrossLinks #UniProt annotated crosslinks
            inBindingRegion #Binding region IntAct
          }
        }`
    : type === "residue" && tab === 0
    ? `query getResidueByPosition{
          residue(anPosition: "${target.toUpperCase()}"){
            anPosition #Residue position
            unRefProtRe #Uniprot reference proteome residue
            afRes #AlphaFold residue
            unAnnNatVariants #UniProt annotated natural variants
            unMoleculeProcessing #signal-peptide/transit-peptide/pro-peptide/chain
            unLocation #localization with respect to membranes
            unMotives #UniProt annotated motives
            unDomains #UniProt annotated domains
            pfamDomainPosHmm #PFAM domain and aligned position in the HMM
            unLigandsBindingSite #UniProt annotated ligands (binding site)
            unAnnPropActiveSite #UniProt annotated properties (active site)
            phosPtms #list of annotated Phosphosite PTMs
            unGlycosylations #UniProt annotated glycosylations
            unLipidations #UniProt annotated lipidations
            unModifiedRes #UniProt annotated modified residues
            unDisulfideBongBridges #UniProt annotated disulphide-bond bridges
            unSecondaryStructure #UniProt annotated secondary structure
            afPredictedSecondaryStructure #AlphaFold predicted secondary structure
            afConfidenceValue #AlphaFold confidence value
            afMaxPredictedNumInteractionResidue #AlphaFold maximum predicted number of interacting residues in a single structure (r = 8A)
            afResiduePredictedInteraction #AlphaFold list of residue with a predicted interaction in at least one structure (r = 8A)
            unCrossLinks #UniProt annotated crosslinks
            inBindingRegion #Binding region IntAct
          }
        }`
    : type === "gene_residue" && tab === 0
    ? `query getResidueByGeneAndPosition{
          residueByGeneSymbolAndPosition(genePosition: "${target.toUpperCase()}"){
            anPosition #Residue position
            unRefProtRe #Uniprot reference proteome residue
            afRes #AlphaFold residue
            unAnnNatVariants #UniProt annotated natural variants
            unMoleculeProcessing #signal-peptide/transit-peptide/pro-peptide/chain
            unLocation #localization with respect to membranes
            unMotives #UniProt annotated motives
            unDomains #UniProt annotated domains
            pfamDomainPosHmm #PFAM domain and aligned position in the HMM
            unLigandsBindingSite #UniProt annotated ligands (binding site)
            unAnnPropActiveSite #UniProt annotated properties (active site)
            phosPtms #list of annotated Phosphosite PTMs
            unGlycosylations #UniProt annotated glycosylations
            unLipidations #UniProt annotated lipidations
            unModifiedRes #UniProt annotated modified residues
            unDisulfideBongBridges #UniProt annotated disulphide-bond bridges
            unSecondaryStructure #UniProt annotated secondary structure
            afPredictedSecondaryStructure #AlphaFold predicted secondary structure
            afConfidenceValue #AlphaFold confidence value
            afMaxPredictedNumInteractionResidue #AlphaFold maximum predicted number of interacting residues in a single structure (r = 8A)
            afResiduePredictedInteraction #AlphaFold list of residue with a predicted interaction in at least one structure (r = 8A)
            unCrossLinks #UniProt annotated crosslinks
            inBindingRegion #Binding region IntAct
          }
        }`
    : type === "gene" && tab === 1
    ? `query getResiduesByGene{
          residuesByGeneSymbol(geneSymbol: "${target.toUpperCase()}", pagination: { offset: 0, limit: 10}, removeNoIntraContacts: true){
            anPosition #Residue position
            intraContacts{#Inter-contact of the residue
            proteinUid#Uniprot Accesion
            pdbId #PDB
            contactPosition#Residue position
          }
          }
        }`
    : type === "protein" && tab === 1
    ? `query getResiduesByProtein{
          residues(proteinAn: "${target.toUpperCase()}", pagination: { offset: 0, limit: 10}, removeNoIntraContacts: true){
            anPosition #Residue position
            intraContacts{#Inter-contact of the residue
            proteinUid#Uniprot Accesion
            pdbId #PDB
            contactPosition#Residue position
          }
          }
        }`
    : type === "residue" && tab === 1
    ? `query getResidueByPosition{
          residue(anPosition: "${target.toUpperCase()}"){
            anPosition #Residue position
            intraContacts{#Inter-contact of the residue
            proteinUid#Uniprot Accesion
            pdbId #PDB
            contactPosition#Residue position
          }
          }
        }`
    : type === "gene_residue" && tab === 1
    ? `query getResidueByGeneAndPosition{
          residueByGeneSymbolAndPosition(genePosition: "${target.toUpperCase()}"){
            anPosition #Residue position
            intraContacts{#Inter-contact of the residue
            proteinUid#Uniprot Accesion
            pdbId #PDB
            contactPosition#Residue position
          }
          }
        }`
    : type === "gene" && tab === 2
    ? `query getResiduesByGene{
          residuesByGeneSymbol(geneSymbol: "${target.toUpperCase()}", pagination: { offset: 0, limit: 10}, removeNoInterContacts: true){
            anPosition #Residue position
            interContacts{#Inter-contact of the residue
            proteinUid#Uniprot Accesion
            pdbId #PDB
            contactPosition#Residue position
          }
          }
        }`
    : type === "protein" && tab === 2
    ? `query getResiduesByProtein{
          residues(proteinAn: "${target.toUpperCase()}", pagination: { offset: 0, limit: 10}, removeNoInterContacts: true){
            anPosition #Residue position
            interContacts{#Inter-contact of the residue
            proteinUid#Uniprot Accesion
            pdbId #PDB
            contactPosition#Residue position
          }
          }
        }`
    : type === "residue" && tab === 2
    ? `query getResidueByPosition{
          residue(anPosition: "${target.toUpperCase()}"){
            anPosition #Residue position
            interContacts{#Inter-contact of the residue
            proteinUid#Uniprot Accesion
            pdbId #PDB
            contactPosition#Residue position
          }
          }
        }`
    : type === "gene_residue" && tab === 2
    ? `query getResidueByGeneAndPosition{
          residueByGeneSymbolAndPosition(genePosition: "${target.toUpperCase()}"){
            anPosition #Residue position
            interContacts{#Inter-contact of the residue
            proteinUid#Uniprot Accesion
            pdbId #PDB
            contactPosition#Residue position
          }
          }
        }`
     : type === "gene" && tab === 3
    ? `query getLigandsByGene{
          ligandsByGene(geneSymbol: "${target.toUpperCase()}"){
            pdbId #PDB
            proteinUid #Uniprot Accession
            chainSymmetry #Chain
            bmId #bmID
            inchikey #inchikey
            ligandId #Ligand ID
            ligandType #Ligand Type
            annotation #Annotation
          }
        }`
    : type === "protein" && tab === 3
    ? `query getResiduesByGene{
          residuesByGeneSymbol(geneSymbol: "${target.toUpperCase()}", pagination: { offset: 0, limit: 10}, removeNoVariant: true){
            pdbId #PDB
            proteinUid #Uniprot Accession
            chainSymmetry #Chain
            bmId #bmID
            inchikey #inchikey
            ligandId #Ligand ID
            ligandType #Ligand Type
            annotation #Annotation
          }
        }`
    : type === "gene" && tab === 4
    ? `query getResiduesByGene{
          residuesByGeneSymbol(geneSymbol: "${target.toUpperCase()}", pagination: { offset: 0, limit: 10}, removeNoVariant: true){
            anPosition #Residue position
            clinvarVariants {#ClinVar variants
            locationG37 # Location of the variants based on hg19
            locationG38 # Location of the variants based on hg38
            ref # Reference allele
            alt # Alternative allele
            variant # The identifier(s) of co-located known variants
            consequence # The consequence type
            clinicalSignificance # The ClinVar clinical significance of the dbSNP variant
            disease # The ClinVar-associated diseases
            proteinPosition # The relative position of amino acid in protein
            aaChange # The reference and variant amino acids
            codon # The reference and variant codon sequence
            alphamissenseScore # The predicted score of AlphaMissense, an amino acid level predictor of pathogenicity
            alphamissenseClass # The predicted class of AlphaMissense, an amino acid level predictor of pathogenicity
            }
          }
        }`
    : type === "protein" && tab === 4
    ? `query getResiduesByProtein{
          residues(proteinAn: "${target.toUpperCase()}", pagination: { offset: 0, limit: 10}, removeNoVariant: true){
            anPosition #Residue position
            clinvarVariants {#ClinVar variants
            locationG37 # Location of the variants based on hg19
            locationG38 # Location of the variants based on hg38
            ref # Reference allele
            alt # Alternative allele
            variant # The identifier(s) of co-located known variants
            consequence # The consequence type
            clinicalSignificance # The ClinVar clinical significance of the dbSNP variant
            disease # The ClinVar-associated diseases
            proteinPosition # The relative position of amino acid in protein
            aaChange # The reference and variant amino acids
            codon # The reference and variant codon sequence
            alphamissenseScore # The predicted score of AlphaMissense, an amino acid level predictor of pathogenicity
            alphamissenseClass # The predicted class of AlphaMissense, an amino acid level predictor of pathogenicity
            }
          }
        }`
    : type === "residue" && tab === 4
    ? `query getResidueByPosition{
          residue(anPosition: "${target.toUpperCase()}"){
            anPosition #Residue position
            clinvarVariants {#ClinVar variants
            locationG37 # Location of the variants based on hg19
            locationG38 # Location of the variants based on hg38
            ref # Reference allele
            alt # Alternative allele
            variant # The identifier(s) of co-located known variants
            consequence # The consequence type
            clinicalSignificance # The ClinVar clinical significance of the dbSNP variant
            disease # The ClinVar-associated diseases
            proteinPosition # The relative position of amino acid in protein
            aaChange # The reference and variant amino acids
            codon # The reference and variant codon sequence
            alphamissenseScore # The predicted score of AlphaMissense, an amino acid level predictor of pathogenicity
            alphamissenseClass # The predicted class of AlphaMissense, an amino acid level predictor of pathogenicity
            }
          }
        }`
    : type === "gene_residue" && tab === 4
    ? `query getResidueByGeneAndPosition{
          residueByGeneSymbolAndPosition(genePosition: "${target.toUpperCase()}"){
            anPosition #Residue position
            clinvarVariants {#ClinVar variants
            locationG37 # Location of the variants based on hg19
            locationG38 # Location of the variants based on hg38
            ref # Reference allele
            alt # Alternative allele
            variant # The identifier(s) of co-located known variants
            consequence # The consequence type
            clinicalSignificance # The ClinVar clinical significance of the dbSNP variant
            disease # The ClinVar-associated diseases
            proteinPosition # The relative position of amino acid in protein
            aaChange # The reference and variant amino acids
            codon # The reference and variant codon sequence
            alphamissenseScore # The predicted score of AlphaMissense, an amino acid level predictor of pathogenicity
            alphamissenseClass # The predicted class of AlphaMissense, an amino acid level predictor of pathogenicity
            }
          }
        }`
    : "";

  return (
            <>
            <Fieldset legend="API query" toggleable collapsed>
              <Button type="button" icon="pi pi-copy" tooltip={messsage} onClick={handleClick}/>
              <InputTextarea
                rows={5}
                cols={30}
                autoResize
                disabled
                value={query}
                ref={textareaRef}
              />
            </Fieldset>
            </>
  )
}

export default GraphlqlQuery