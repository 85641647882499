import React from 'react';
import { Chart } from 'primereact/chart';

// Fixed color dictionaries
const consequenceColorMap = {
  "missense_variant": '#e6194b',
  "synonymous_variant":'#3cb44b',
  "stop_gained": '#ffe119',
  "frameshift_variant": '#4363d8',
  "inframe_insertion": '#f58231',
  "start_lost": '#911eb4',
  "splice_region_variant": '#46f0f0',
  "inframe_deletion": '#f032e6',
  "protein_altering_variant": '#bcf60c',
  "NMD_transcript_variant": '#fabebe',
  "coding_sequence_variant": '#008080',
  "incomplete_terminal_codon_variant": '#e6beff',
  "splice_acceptor_variant" : '#9a6324',
  "stop_retained_variant" : '#fffac8',
  "splice_donor_region_variant" : '#800000',
  "start_retained_variant": '#aaffc3',
  "splice_donor_5th_base_variant": '#808000'
};

const aaColorMap = {
  "benign": "blue",
  "pathogenic": "red",
  "ambiguous": "#FFCE56",
  "no_prediction": "#4BC0C0"
};

const clinicalColorMap = {
    'benign' :"blue",
    'likely_benign': '#268bff',
    'conflicting_interpretations_of_pathogenicity': '#bd3d3d',
    'likely_pathogenic': '#b41414',
    'pathogenic': "red",
    'risk_factor': '#00c8a8',
    'no_clinical_significance': '#bc0c5d',
    'other': '#2d572c',
    'uncertain_significance': '#c8aa00'
}

// Get color for labels based on type
const getColor = (label, type) => {
  if (type === 'consequence') {
    return consequenceColorMap[label] || '#000000';
  } else if (type === 'aa') {
    return aaColorMap[label] || '#000000';
  } else if (type === 'clinical') {
    return clinicalColorMap[label] || '#000000';
  }
  return '#000000';
};

const BarChart = ({ VariantData, title, consequence, clinical, aa, selectedVariants, positionSummary }) => {


  // Count occurrences of each protein position
  const countVariantsByPosition = (variants) => {
    const counts = {};
    variants.forEach((variant) => {
      counts[variant.proteinPosition] = (counts[variant.proteinPosition] || 0) + 1;
    });
    return counts;
  };

  // Get the top 10 protein positions
  const getTop10Positions = (counts) => {
    return Object.entries(counts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 10)
      .map(([position]) => parseInt(position));
  };

  // Calculate the positions based on selected variants
  const getPositionsBasedOnSelection = (variants) => {
    if (!selectedVariants || selectedVariants.length === 0 && !positionSummary) {
      // If no variant is selected, return the top 10 positions
      const variantCounts = countVariantsByPosition(variants);
      return getTop10Positions(variantCounts);
    } else if (selectedVariants.length === 1) {
      // If one variant is selected, return 5 positions before and 5 positions after
      const selectedPosition = selectedVariants[0];
      const positions = [];

      const startPosition = Math.max(selectedPosition - 5, 1);
      const endPosition = selectedPosition + 5;

      for (let i = startPosition; i <= endPosition; i++) {
        positions.push(i);
      }
      return positions;
    } else if (positionSummary && (!selectedVariants || selectedVariants.length === 0)) {

      const positions = [];

      const startPosition = Math.max(parseInt(positionSummary) - 5, 1);
      const endPosition = parseInt(positionSummary) + 5;

      for (let i = startPosition; i <= endPosition; i++) {
        positions.push(i);
      }
      return positions;
    }  else {
      // If multiple variants are selected, return their exact positions
      const variants = [...new Set(selectedVariants)]
      return variants;
    }
  };

  const topPositions = getPositionsBasedOnSelection(VariantData);

  // Sort top positions only if selectedVariants is empty
  if (!selectedVariants || selectedVariants.length === 0) {
    topPositions.sort((a, b) => a - b); // Ordena as posições em ordem crescente
  }

  // Filter the arrays based on data found in the top positions
  const filterValues = (variants, key, values = []) => {
    const foundValues = new Set();

    variants.forEach((variant) => {
      if (topPositions.includes(parseInt(variant.proteinPosition))) {
        variant[key]?.split(',').forEach((item) => {
          foundValues.add(item.trim().toUpperCase());
        });
      }
    });

    // Return filtered array based on found values in the top positions
    return values.filter(value => foundValues.has(value.label.trim().toUpperCase()));
  };

  // Applying the filterValues function to filter the arrays
  const filteredConsequence = filterValues(VariantData, 'consequence', consequence);
  const filteredClinical = filterValues(VariantData, 'clinicalSignificance', clinical);
  const filteredAA = filterValues(VariantData, 'alphamissenseClass', aa);

  // Count occurrences of each label for each top protein position
  const getCountsByPosition = (variants, key, values, positions) => {
    return values.map((value) => {
      return positions.map((position) => {
        // Handle multiple labels within a single field using splitting
        const count = variants.filter((variant) => {
          const variantLabels = variant[key].split(',').map(item => item.toUpperCase().trim());
          return variantLabels.includes(value.label.toUpperCase().trim()) && parseInt(variant.proteinPosition) === parseInt(position);
        }).length;
        return count;
      });
    });
  };

  const data = [];

  // Generate datasets based on title
  if (title === 'Consequence') {
    const counts = getCountsByPosition(VariantData, 'consequence', filteredConsequence, topPositions);
    filteredConsequence.forEach((value, index) => {
      data.push({
        type: 'bar',
        label: value.label,
        backgroundColor: getColor(value.label, 'consequence'),
        data: counts[index],
      });
    });
  } else if (title === 'Clinical Significance') {
    const counts = getCountsByPosition(VariantData, 'clinicalSignificance', filteredClinical, topPositions);
    filteredClinical.forEach((value, index) => {
      data.push({
        type: 'bar',
        label: value.label,
        backgroundColor: getColor(value.label, 'clinical'),
        data: counts[index],
      });
    });
  } else if (title === 'AlphaMissense class') {
    const counts = getCountsByPosition(VariantData, 'alphamissenseClass', filteredAA, topPositions);
    filteredAA.forEach((value, index) => {
      data.push({
        type: 'bar',
        label: value.label,
        backgroundColor: getColor(value.label, 'aa'),
        data: counts[index],
      });
    });
  }

  const stackedData = {
    labels: topPositions,
    datasets: data,
  };

  const stackedOptions = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      legend: {
        labels: {
          color: '#495057',
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: '#495057',
        },
        grid: {
          color: '#ebedef',
        },
      },
      y: {
        stacked: true,
        ticks: {
          color: '#495057',
        },
        grid: {
          color: '#ebedef',
        },
      },
    },
  };

  return (
    <div>
      <div className="card">
        <h5>{title}</h5>
        <Chart type="bar" data={stackedData} options={stackedOptions} />
      </div>
    </div>
  );
};

export default BarChart;
