import React, { useState, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import {Column} from 'primereact/column';
import { MultiSelect } from 'primereact/multiselect';

const ResiduesTable = ({ ResidueData }) => {
       const dt = useRef(null);

 const handleClick = () => {
    navigator.clipboard.writeText(window.location.href);
  };

       const columns = [
       { field: 'anPosition', header: 'Position' },
       { field: 'unRefProtRe', header: 'Ref' },
       { field: 'afRes', header: 'afRef' },
       { field: 'unAnnNatVariants', header: 'Natural variants' },
       { field: 'unMoleculeProcessing', header: 'Molecule processing' },
       { field: 'unLocation', header: 'Location' },
       { field: 'unMotives', header: 'Motives' },
       { field: 'unDomains', header: 'Domains' },
       { field: 'pfamDomainPosHmm', header: 'PFAM-HMM Pos' },
       { field: 'unLigandsBindingSite', header: 'Binding site' },
       { field: 'unAnnPropActiveSite', header: 'Active site'},
       { field: 'phosPtms', header: 'PTMs' },
       { field: 'unGlycosylations', header: 'Glycosylations' },
       { field: 'unLipidations', header: 'Lipidations' },
       { field: 'unModifiedRes', header: 'Modified Residue' },
       { field: 'unDisulfideBongBridges', header: 'Disulfide bond bridges' },
       { field: 'unSecondaryStructure', header: 'Secondary Structure' },
       { field: 'afPredictedSecondaryStructure', header: 'AF-Predicted secondary structure' },
       { field: 'afConfidenceValue', header: 'AF-Confidence Value' },
       { field: 'afMaxPredictedNumInteractionResidue', header: 'AF-Max predicted IntRes' },
       { field: 'afResiduePredictedInteraction', header: 'AF-Predicted residues' },
       { field: 'unCrossLinks', header: 'CrossLinks' },
       { field: 'inBindingRegion', header: 'Binding Region' }

   ];

 const multiSelectOptions = columns.map(col => ({ label: col.header, value: col.field }));
 const [visibleColumns, setVisibleColumns] = useState(columns);

   const onColumnToggle = (event) => {
    const selectedFieldNames = event.value;
    const updatedVisibleColumns = columns.filter(col => selectedFieldNames.includes(col.field));
    setVisibleColumns(updatedVisibleColumns);
   };

  const dynamicColumns = visibleColumns.map((col) => {
    return <Column key={col.field} field={col.field} header={col.header} sortable />;
 });

const exportCSV = (tableRef, selectionOnly) => {
        tableRef.current.exportCSV({ selectionOnly });
  };

  const exportExcel = async (dataToExport) => {
    const xlsx = await import('xlsx');
    const worksheet = xlsx.utils.json_to_sheet(dataToExport);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
    saveAsExcelFile(excelBuffer, 'data');
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };


     const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] }
    });

   const value = filters['global'] ? filters['global'].value : '';

    const onGlobalFilterChange = (event) => {
        const value = event.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
    };

  const header = (
  <div className="row align-items-center">
      <div className="col mb-2">
          <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)}
               placeholder="Global Search" />
          </span>
      </div>
      <div className="col" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <MultiSelect
              value={visibleColumns.map(col => col.field)}
              options={multiSelectOptions}
              onChange={onColumnToggle}
              optionLabel="label"
              optionValue="value"
              placeholder="Select Columns"
              display="chip"
              style={{ width: '200px', marginRight: '10px' }}
          />
          <Button type="button" icon="pi pi-file" className="p-button-rounded p-mr-2" onClick={() => exportCSV(dt, false)} data-pr-tooltip="CSV" />
          <Button type="button" icon="pi pi-file-excel" className="p-button-success p-button-rounded p-mr-2" onClick={() => exportExcel(ResidueData)} data-pr-tooltip="XLS" />
          <Button type="button" icon="pi pi-link" severity="help"  className="p-button-rounded p-mr-2" onClick={handleClick}/>
      </div>
  </div>
);


  return (
<>
 <DataTable ref={dt} value={ResidueData} paginator rows={5} removableSort header={header}
               filters={filters} onFilter={(e) => setFilters(e.filters)} tableStyle={{ minWidth: '50rem' }}>
                    {dynamicColumns}
 </DataTable>
</>
  )
}

export default ResiduesTable