import React, { useState, useEffect } from 'react';

const FunctionalAnnotationStructureLegend = ({ annotation, colors }) => {
  const [items, setItems] = useState([]);

  // Mapping object for annotations with corresponding text and color
  const annotationMapping = {
    unAnnNatVariants: { text: "Variant position", color: "rgb(178, 24, 43)" },
    unMotives: { text: "Motive", color: "#008000" },
    pfamDomainPosHmm: { text: "PFAM-HMM Pos", color: "#008000" },
    unLigandsBindingSite: { text: "Binding site", color: "#008000" },
    unAnnPropActiveSite: { text: "Active site", color: "#008000" },
    unGlycosylations: { text: "Glycosylation", color: "#008000" },
    unLipidations: { text: "Lipidation", color: "#008000" },
    unModifiedRes: { text: "Modified Residue", color: "#008000" },
    unDisulfideBongBridges: { text: "Disulfide bond bridge", color: "#008000" },
    unCrossLinks: { text: "CrossLink", color: "#008000" },
    inBindingRegion: { text: "Binding Region", color: "#008000" },
    afResiduePredictedInteraction: { text: "AF-Predicted residue", color: "#008000" },
    None: { text: "", color: "#F9FAFD" },
    unSecondaryStructure: [
      { text: "HELIX", color: "#838ffc" },
      { text: "STRAND", color: "#da54a3" },
    ],
     unMoleculeProcessing: [
      { text: "CHAIN", color: "#838ffc" },
      { text: "SIGNAL", color: "#da54a3" },
      { text: "TRANSIT", color: "#820e44" },
      { text: "PRO_*", color: "#c746d4" },
    ],
    unLocation: [
      { text: "EXTRAMEM", color: "#838ffc" },
      { text: "TRANSMEM", color: "#da54a3" },
      { text: "INTRAMEM", color: "#820e44" }
    ],
    afPredictedSecondaryStructure: [
      { text: "BEND", color: "#66D8C9" },
      { text: "TURN", color: "#00B266" },
      { text: "HELX", color: "#FF0080" },
      { text: "STRN", color: "#FFC800" }
    ],
    phosPtms:  [
      { text: "Phosphorylation", color: "#4393C3" },
      { text: "Acetylation", color: "#5aae61" },
      { text: "Ubiquitylation", color: "#bf812d" },
      { text: "Other", color: "#666666" },
      { text: "Multiple PTMs", color: "#66D8C9" }
      ],
      pLDDT:  [
      { text: "V. High", color: "#0053d6" },
      { text: "High", color: "#65cbf3" },
      { text: "Low ", color: "#ffdb13" },
      { text: "V. Low", color: "#ff7d45" }
      ],
      AlphaFill :  [
      { text: "V. High", color: "#0053d6" },
      { text: "High", color: "#65cbf3" },
      { text: "Low", color: "#ffdb13" },
      { text: "V. Low", color: "#ff7d45" }
      ],
      hydrophobicity: [
       { text: "Hydrophilic", color: "#a50026" },
       { text: "Hydrophobic", color: "#006837" }
      ],
      surface: [
       { text: "Buried", color: "#3361E1" },
       { text: "Exposed", color: "#BF2222" }
      ],
      unDomains: Object.entries(colors).map(([text, color]) => ({
          text: text.replace(/\s+/g, ' '),  // Replace all newline characters with a space
          color: `#${color}`
        }))
      };

  useEffect(() => {
    // Get the corresponding item(s) from the mapping if the annotation exists
    const selectedItems = annotationMapping[annotation] || [];
    setItems(Array.isArray(selectedItems) ? selectedItems : [selectedItems]);
  }, [annotation]);

  return (
   <div className="d-flex flex-column">
      {items.map((item, index) => (
        <div key={index} className="d-flex align-items-center mb-2"> {/* Align items vertically */}
          <div className="burble" style={{ background: item.color, marginRight: '2px' }}></div> {/* Set height and width for the color block */}
          <p className="fs--2 text-start mb-0">{item.text}</p> {/* Added margin for spacing */}
        </div>
      ))}
    </div>
  );
};

export default FunctionalAnnotationStructureLegend;
